import { removeField } from "lib/function"
import Client from "../client/ClientComiru"
import version from "../version"
const resource = version
function getNotificationList(params) {
  return Client.get(`${resource}/notifications/all`, { params })
}

function createNotification(data) {
  return Client.post(`${resource}/notifications`, data)
}

function updateNotification(data) {
  return Client.put(`${resource}/notifications/${data?.id}`, removeField(data, "id"))
}

function deleteNotification(id) {
  return Client.delete(`${resource}/notifications/${id}`)
}

function getNotificationDetails(id) {
  return Client.get(`${resource}/notifications/${id}`)
}

const api = {
  getNotificationList,
  createNotification,
  deleteNotification,
  getNotificationDetails,
  updateNotification
}
export default api
