import queryString from "query-string"
import { getEnums } from "./localstorage"
// import { iconWarning } from "./icons"
import dayjs from "dayjs"
import { Image } from "antd"
import confirmLogoutImage from "assets/images/confirmLogoutImage.png"
import imageFalse from "assets/images/remove_false.png"
import { getBaseDataLocal } from "./localstorage"
import { currency } from "components/Dropdown/DropdownCurrency/listOfCurrency"
import { countrys } from "components/Dropdown/DropdownCountry/Country"
import { myFonts, themeColors } from "./theme"
import { BILLING_METHOD } from "./Const"

export function downloadBlob(filename, content) {
  var blob = new Blob([content], { type: "text/csv;charset=utf-8;" })
  var url = URL.createObjectURL(blob)
  var pom = document.createElement("a")
  pom.href = url
  let timeName = "-" + dayjs().format("YYYY-MM-DD HH MM ss") + ".csv"
  pom.setAttribute("download", filename + timeName)
  pom.click()
}

export function convertModal({
  text,
  description,
  image = confirmLogoutImage,
  className,
  width = 230,
  height = 190
}) {
  return {
    className: className || "modal_confirm",
    icon: null,
    centered: true,
    title: null,
    cancelText: "Cancel",
    content: (
      <div>
        <div className="flex justify-center my-5">
          <Image width={width} height={height} preview={false} src={image} />
        </div>
        <div className="text-center font-bold text-lg text-dark-500 mb-2">{text}</div>
        <div className="text-center text-dark-400 text-sm px-4">{description}</div>
      </div>
    ),
    okText: "Confirm"
  }
}

export function convertModalFalse({ text, description, t }) {
  return {
    okButtonProps: {
      style: {
        display: "none"
      }
    },
    cancelButtonProps: { type: "primary", danger: false, style: { width: "100%" } },
    icon: null,
    centered: true,
    title: null,
    content: (
      <div>
        <div className="flex justify-center my-5">
          <Image width={190} height={190} preview={false} src={imageFalse} />
        </div>
        <div className="text-center font-bold text-lg text-dark-500 mb-2">{text}</div>
        <div className="text-center text-dark-400 text-sm px-4 py-4">{description}</div>
      </div>
    ),
    cancelText: t("Cancel")
  }
}

export const paramsUrl = {
  get: () => {
    return queryString.parse(window.location.search)
  },
  set: (params) => {
    const currentUrlParams = queryString.stringify(params, {
      skipNull: true
    })
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${currentUrlParams.toString()}`
    )
  }
}

export function getSearchParams() {
  return queryString.parse(window.location.search)
}

export function getRole() {
  const role = localStorage.getItem("role")
  return role || false
}

export function getToken() {
  return localStorage.getItem("accessToken")
}

export function convertEnumToDropdown(key, t) {
  const enums = getEnums()
  if (t)
    return enums[key].map((i) => {
      return {
        ...i,
        label: t(i.label)
      }
    })
  return enums[key]
}

export function getServiceCode(objectCode) {
  const application = JSON.parse(localStorage.getItem("application"))
  return `${application?.organization?.code}-${objectCode}`
}

export function hexToRgba(hex, alpha) {
  if (!hex?.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i)) {
    return null
  }

  hex = hex.replace(/^#/, "")
  let r, g, b
  if (hex.length === 3) {
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16)
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16)
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16)
  } else {
    r = parseInt(hex.substring(0, 2), 16)
    g = parseInt(hex.substring(2, 4), 16)
    b = parseInt(hex.substring(4, 6), 16)
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export function formatCurrency(number, code) {
  if (parseFloat(number) === 0 && !code) return "Free"
  if (number && code) {
    let obj = currency[code]
    if (!obj) return "N/A"
    return new Intl.NumberFormat(obj.locale, {
      style: "currency",
      currency: obj.currency,
      currencyDisplay: "code"
    })
      .format(number)
      .replace(obj.currency, "")
      .trim()
  }
  return "N/A"
}

export function payType(v) {
  if (v === 0) return "Bank transfer"
  if (v === 1) return "Cash"
  if (v === 3) return "Stripe"
  if (v === 4) return "Pay JP"
}

export function renderTime(timeString) {
  const time = dayjs(timeString)
  const now = dayjs()
  const diff = now.diff(time, "seconds")
  let timeAgoString = ""

  if (diff < 60) {
    timeAgoString = `${parseInt(diff)}s`
  } else if (diff < 3600) {
    timeAgoString = `${parseInt(diff / 60)}m`
  } else if (diff < 86400) {
    timeAgoString = `${parseInt(diff / 3600)}h`
  } else if (diff < 2678400) {
    timeAgoString = `${parseInt(diff / 86400)}d`
  } else {
    timeAgoString = getDateSetting(timeString)
  }
  // else if (diff < 32140800) {
  //   timeAgoString = `${parseInt(diff / 2678400)} months ago`
  // } else {
  //   timeAgoString = `${parseInt(diff / 32140800)} years ago`
  // }
  return timeAgoString
}

export function renderTimeLastActivity(timeString) {
  const time = dayjs(timeString)
  const now = dayjs()
  const diff = now.diff(time, "seconds")
  let timeAgoString = ""

  if (diff < 60) {
    timeAgoString = `${parseInt(diff)}s ago`
  } else if (diff < 3600) {
    timeAgoString = `${parseInt(diff / 60)}m ago`
  } else if (diff < 86400) {
    timeAgoString = `${parseInt(diff / 3600)}h ago`
  } else if (diff < 2678400) {
    timeAgoString = `${parseInt(diff / 86400)}d ago`
  } else {
    timeAgoString = getDateSetting(timeString)
  }
  return timeAgoString
}

export function getFormatSpecificDate() {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let _format = "YYYY-MM-DD"
  if (data) {
    _format = data?.value?.date_format || "YYYY-MM-DD"
  }
  return "YYYY-MM-DD (ddd)"
}

export function getFormatSpecificTime() {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let _format = "HH:mm"
  if (data) {
    _format = data?.value?.time_format || "HH:mm"
  }
  return _format
}

export function getFormatDateSetting() {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let _format = "YYYY-MM-DD HH:mm"
  if (data) {
    _format = `${getFormatSpecificDate()} ${getFormatSpecificTime()}`
  }
  return _format
}

export function getDateSetting(date, format = "YYYY-MM-DD HH:mm") {
  const { settings } = getBaseDataLocal()

  const data = settings?.find((v) => v.key === "application-setting")
  let _format = format
  if (data) {
    _format = getFormatDateSetting() || format
  }

  if (!date) return "N/A"
  return dayjs(date).format(_format)
}

export function getCurrency(code) {
  let obj = currency[code]
  if (obj) return currency[code].currency
  return ""
}

export function getFirstElement(body) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(body, "text/html")
  const firstElement = doc.body.firstElementChild

  // Nếu body không có element nào thì firstElement sẽ là null
  const firstElementHtml = firstElement ? firstElement.outerHTML : ""
  return firstElementHtml
}
export function getCountryName(code) {
  let contry = countrys.find((i) => i.value === code)
  return contry ? contry.label : "N/A"
}
export function getActiveAction(action) {
  const { permission } = getBaseDataLocal()
  return !!permission.find((i) => i === action)
}
export function getCurrentTimezone() {
  const date = new Date()
  const offset = -date.getTimezoneOffset() / 60 // đổi phút sang giờ và đảo dấu
  const offsetSign = offset >= 0 ? "+" : "-"
  const formattedOffset = `${offsetSign}${Math.abs(offset)}`
  return formattedOffset
}

export function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          resolve(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`)
        },
        (error) => {
          console.error("Error getting location:", error)
          resolve(null)
        }
      )
    } else {
      const error = new Error("Geolocation is not supported by this browser.")
      console.error(error.message)
      resolve(null)
    }
  })
}
export function getLimitBilling(v) {
  return v === -1 ? "Unlimited" : v
}
export function getThemeConfigs(configs) {
  const themeSetting = configs.find((i) => i.key === "theme-setting")
  const { color_palette_type, typography, logo, name, favicon } = themeSetting?.value || {}
  const themeKey = color_palette_type || 1
  const itemFont = myFonts.find((i) => i.value === typography)

  return {
    color: themeColors.find((i) => i.value === themeKey)?.color,
    fontFamily: itemFont?.label || "Lato",
    themeKey,
    favicon,
    logo,
    name
  }
}

export function applyTheme(settingTheme) {
  if (settingTheme?.fontFamily) {
    const itemFont = myFonts.find((i) => i.label === settingTheme.fontFamily)
    var link = document.createElement("link")
    link.setAttribute("rel", "stylesheet")
    link.setAttribute("type", "text/css")
    link.setAttribute("href", itemFont.href)
    document.head.appendChild(link)
    document.body.style.fontFamily = settingTheme.fontFamily
    document.documentElement.setAttribute("data-theme", `theme${settingTheme.themeKey}`)
    const linkElement = document.querySelector("link[rel=icon]")
    linkElement.href = settingTheme.favicon
  }
}

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function getDomainFromUrl(inputUrl) {
  try {
    const url = new URL(inputUrl)
    return url.hostname // Trả về hostname (domain)
  } catch (error) {
    console.error("Invalid URL", error)
    return null
  }
}
export const initMonthString = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

export function getLocale() {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let language = data?.value?.language || "jp"
  return language
}

export function formatNumber(input) {
  const number = typeof input === "number" ? input : Number(input)

  if (isNaN(number)) {
    return null
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function isJapanPostBank(bankAccount) {
  return bankAccount?.account_type == null && bankAccount?.bank_type === 0
}
export function getPaymentMethod(v) {
  if (v?.account_number === "" && v?.account_type === null) {
    return "Bank transfer"
  }
  if (v?.account_type == null && v?.bank_type === 0) {
    return "Postal automatic withdrawal"
  }
  if (v?.bank_type !== 0 && v?.bank?.code === "0167") {
    return "Sanin withdrawal"
  }
  if (v?.bank_type !== 0 && v?.bank?.code === "0157") {
    return "Shiga withdrawal"
  }
  if (v?.bank_type !== 0 && v?.bank?.code === "0179") {
    return "Saga withdrawal"
  }
  if (v?.bank_type !== 0 && v?.bank?.code === "0147") {
    return "Fukui withdrawal"
  }
  return v?.bank?.name || ""
}

export function getBillingMethodName(v) {
  switch (v) {
    case BILLING_METHOD.BANK_TRANSFER:
      return "Joint bank withdrawal"
    case BILLING_METHOD.POST_TRANSFER:
      return "Joint postal withdrawal"
    case BILLING_METHOD.SANIN_WITHDRAWAL:
      return "Sanin withdrawal"
    case BILLING_METHOD.SHIGA_WITHDRAWAL:
      return "Shiga withdrawal"
    case BILLING_METHOD.SAGA_WITHDRAWAL:
      return "Saga withdrawal"
    case BILLING_METHOD.FUKUI_WITHDRAWAL:
      return "Fukui withdrawal"
    default:
      return "-"
  }
}

export const getBirthDate = (record, t) =>
  record?.birth_date ? dayjs(record?.birth_date).format("YYYY-MM-DD") : t("N/A")

export const removeFalsyValues = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => Boolean(value)))
}

export const removeField = (data, fieldToRemove) => {
  const { [fieldToRemove]: _, ...rest } = data
  return rest
}

export const renderTimeTable = (time, format = "") => (time ? dayjs(time).format(format) : "-")
