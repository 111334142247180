export const RESOURCE_KEY = {
  CLIENT: "view_client",
  DASHBOARD: "view_dashboard",
  BILLING: "view_billing",
  BILLING_STATIC: "view_billing_statistic",
  BRAND: "view_organization",
  PACKAGE: "view_package",
  USER: "user",
  USER_ROLE: "view_group",
  USER_USERS: "view_user",
  USER_PROFILE: "view_user_profile",
  DOMAIN: "view_domain",
  APPROVAL: "view_approval",
  NOTIFICATION: "notification",
  NOTIFICATION_LIST: "view_notification",
  NOTIFICATION_SEMENT: "view_segment",
  MY_NOTIFICATION: "view_my_notification",
  SUPPORT_TICKET: "view_support_ticket",
  SETTING: "setting",
  SETTING_GENERAL: "view_general_setting",
  SETTING_APPLICATION: "view_application_setting",
  SETTING_EMAIL: "view_email_setting",
  SETTING_BILLING: "view_billing_setting",
  SETTING_APPROVAL: "view_approval_setting",
  SETTING_SECURITY: "view_security_setting",
  SETTING_THEME: "view_theme_setting",
  SETTING_DOMAIN: "view_domain_setting",
  SETTING_SYSTEM: "view_system_maintenance_setting",
  ACTIVITY_LOG: "view_all_activity_log",
  SCHOOL_LIST: "view_dashboard",
  SCHOOL: "view_dashboard",
  SETTING_ORGANIZATION: "view_dashboard",
  SETTING_STUDENT: "view_dashboard",
  NOTIFICATION_MANAGEMENT: "view_notification_management",
  TEACHING_REPORT: "view_teaching_report",
  CHAT: "view_chat",
  ATTENDANCE_MANAGEMENT: "view_attendance_management",
  SETTING_COMMUNICATION: "view_communication_setting",
  ATTENDANCE_SETTING: "view_attendance_setting"
}

export const ENVIRONMENT = [
  {
    label: "Development",
    value: "development"
  },
  {
    label: "Testing",
    value: "testing"
  },
  {
    label: "Staging",
    value: "staging"
  },
  {
    label: "Production",
    value: "production"
  }
]

export const configLocale = {
  designFileId: process.env.REACT_APP_TRANSLATE_DESIGN_FILE,
  projectId: process.env.REACT_APP_TRANSLATE_PROJECT_ID
}

export const TYPE_BLOCK_NOTI = {
  APPROVAL: 1,
  NEWS: 2,
  MARKETING: 3,
  PROMOTION: 4,
  EVENT: 5,
  TASK: 6
}

export const Approval_Status = {
  approved: "approved",
  rejected: "rejected",
  in_process: "in_process"
}
export const GENDER_TYPE = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}

export const ORGANIZATION_SETTING_TYPE = {
  adminPassword: 1,
  enduserPassword: 2,
  accessLimit: 3,
  otp: 4
}

export const Setting_Password_Policy = {
  allow_special_character: "allow_special_character",
  alphabetic_letter_required: "alphabetic_letter_required",
  lowercase_letter_required: "lowercase_letter_required",
  uppercase_letter_required: "uppercase_letter_required",
  numbers_required: "numbers_required",
  password_min_length: "password_min_length",
  password_max_length: "password_max_length",
  allowed_character_checked: "allowed_character_checked",
  password_enforce_history: "password_enforce_history",
  special_symbol_required: "special_symbol_required"
}

export const AUTHORITY_TYPE = {
  AREA_MANGER: 1,
  SCHOOL_MANAGER: 2,
  SCHOOL_MANAGER_CUSTOMIZATION: 3,
  SYSTEM_ADMIN: 4,
  ACCOUNTING_STAFF: 5
}

export const ENUMS_CONFIG = {
  authority_type: [
    {
      label: "System administrator",
      value: 4
    },
    {
      label: "Accounting staff",
      value: 5
    },
    {
      label: "Area manager",
      value: 1
    },
    {
      label: "Classroom instructor",
      value: 2
    },
    {
      label: "Customization",
      value: 3
    }
  ]
}

export const SendNotificationType = {
  END_USER: 1,
  PORTAL_USER: 2
}

export const SendNotificationStatus = {
  DRAFT: 1,
  SCHEDULED: 2,
  SENT: 3
}

export const EMAIL_TYPE = {
  Confirm: 1,
  Reset: 2
}

export const ERROR_API_CODE = {
  Resource_Not_Found: "IDM_RNF_404",
  Unable_To_Login: "IDM_IUPWD_400",
  Resource_Deactive: "IDM_RD_423",
  User_Deactive: "IDM_UD_423",
  User_Verify: "IDM_UVY_410",
  User_Not_Found: "IDM_UNF_404",
  User_Locked: "IDM_IULOCKED_400", // user is locked because of logining many times.
  Fail_Parse_Json: "IDM_FTPJB_400",
  Missing_Authorization_Token: "IDM_MAT_404",
  Fail_Validate_Authorization_Token: "IDM_FTVAT_401",
  Fail_Get_Token_Claims: "IDM_FTGTC_400",
  Permission_Not_Allow: "IDM_PNA_403",
  Item_Already_Exists: "IDM_IAE_409",
  Title_Can_Not_Blank: "IDM_TCNBB_400",
  Item_Not_Found: "IDM_INF_404",
  Record_Not_Found: "IDM_RCNF_404",
  Can_Not_Update_Finished_Item: "IDM_CNUFI_400",
  Data_Provided_Not_Correct: "IDM_TDPINC_400",
  Data_Provided_Forbidden: "IDM_TDPIF_403",
  Acccess_Token_Revoked: "IDM_ACT_401",
  Refresh_Token_Revoked: "IDM_RFT_401",
  Password_Incorrect: "IDM_PWDI_400",
  Username_Already_Exists: "IDM_UAE_409",
  Organization_Already_Exists: "IDM_ORGAE_409",
  Service_Provider_Code_Already_Exists: "IDM_SPCAE_409",
  Verification_Confirm_Code_Expired: "IDM_VUCCE_417",
  Verification_Confirm_Code_Invalid: "IDM_VUCCI_400",
  Role_Name_Duplicated: "IDM_RND_409",
  Client_Not_Found: "IDM_CLF_404",
  Invalid_Client_Secret: "IDM_ICS_400",
  ClientID_Taken: "IDM_CIDT_400",
  Authorization_Code_Not_Found: "IDM_ACNF_400",
  Authorization_Code_Expired: "IDM_ACE_417",
  Invalid_RedirectURI: "IDM_IRURL_400",
  Invalid_Scope: "IDM_IC_400",
  Refresh_Token_Not_Found: "IDM_RFTNF_404",
  Refresh_Token_Expired: "IDM_RFTE_401",
  Requested_Scope_Cannot_Be_Greater: "IDM_RSCBG_400",
  Error_When_Substring: "ErrorWhenSubstring"
}

export const classTime = [
  {
    label: "Account study",
    value: 1
  },
  {
    label: "Account PC",
    value: 2
  }
]

export function listSeatStatus({ t }) {
  return [
    {
      label: t("All"),
      value: "all"
    },
    {
      label: t("one_to_one"),
      value: 0
    },
    {
      label: t("one_to_two"),
      value: 1
    },
    {
      label: t("Attendance optional"),
      value: 2
    }
  ]
}

export function listSeatStatusViewMonth({ t }) {
  return [
    {
      label: t("All"),
      value: "all"
    },
    {
      label: "1:1",
      value: "11"
    },
    {
      label: "1:2",
      value: "12"
    },
    {
      label: t("Unlimited individual lessons"),
      value: "attendance_optional"
    }
  ]
}
export const studentScheduleStatus = {
  scheduled: "Scheduled", // Yotei-zumi
  seat_assignment: "Seat assignment", // Zaseki waritate
  transfer_complete: "Transfer complete", // Tenkin kanryou
  transfer_portion: "Transfer portion", // Ichibu tenkin
  scheduled_transfer: "Scheduled transfer", // Tenkin yotei
  seat_assignment_transfer: "Seat assignment transfer", // Zaseki idou
  absence: "Absence", // Kesseki
  inactivate: "Inactivate",
  not_implement: "Not implement"
  // Mukou
}
export const studentTimeClass = {
  0: "All",
  1: "First half",
  2: "Second half"
}
export const studentClassType = {
  1: "1:1",
  2: "1:2",
  3: "1:3",
  4: "1:4",
  5: "1:5"
}

export const studentStringStyle = {
  110: "1:1",
  120: "1:2",
  130: "受放"
}

export const BILLING_TYPE = {
  NORMAL_TRANSFER: 0,
  SEPARATE_TRANSFER: 1,
  NEW_COURSE: 2
}

export const BILLING_METHOD = {
  BANK_TRANSFER: 0,
  POST_TRANSFER: 1,
  SANIN_WITHDRAWAL: 2,
  SHIGA_WITHDRAWAL: 3,
  SAGA_WITHDRAWAL: 4,
  FUKUI_WITHDRAWAL: 5
}

export const BILLING_SITUATION = {
  UNCONFIRMED: 0,
  IN_PROGRESS: 1,
  PAYMENT_CONFIRMED: 2,
  ALL_BANK_COMPLETE: 3,
  SURPLUS_TRANFER: 4,
  SURPLUS: 5,
  NOT_AVAILABLE: 6
}

export const EXPENSE_ITEM = {
  NONE: 0,
  MANAGEMENT_FEE_BILL: 1,
  NEGATIVE_ACCOUNT_PERMISSION: 2
}
export const CLASS_TYPE = [
  { value: "reading", label: "速読" }, // Sokudoku
  { value: "video", label: "ビデオ" }, // Bideo
  { value: "other", label: "その他" } // Sono ta
]

export const LESSON_TYPE = [
  { value: "regular", label: "regular" }, // Tsuujou
  { value: "training", label: "training" }, // Kenshuu
  { value: "new_course", label: "new_course" } // Shinki koosu
]
export const billingFormat = {
  0: "1:1",
  1: "1:2",
  2: "受放"
}
export const lessonKindType = {
  0: "1:N", //SEAT_ONE_TO_DEFAULT
  10: "1:1",
  20: "1:2",
  90: "1:N", //SEAT_ONE_TO_MANY
  100: "N/A"
}

export const SALARIES_LIST_SITUATION = {
  IN_PROGRESS: 0,
  OUTPUT_BANK: 1,
  PAYMENT_CONFIRMED: 2,
  PAYMENT_DISCREPANCY: 3,
  TRANSFER_FAIL: 4
}

export const interviewType = {
  0: "在校生面談", // Zaikousei mendan
  1: "保護者面談", // Hogosha mendan
  4: "新規面談", // Shinki mendan
  5: "講師面談", // Kyoushi mendan
  6: "講師研修", // Koushi kenshuu
  7: "体験ビデオ授業", // Taiken bideo jugyou
  8: "来客" // Raikyaku
}

export const ROLES_LIST = [
  {
    label: "Student",
    value: "student"
  },
  {
    label: "Teacher",
    value: "teacher"
  }
]

export const ROLES_ID_LIST = {
  student: 7,
  teacher: 6
}

// 6 teacher, 7 student

export const ATTENDANCE_TABS = {
  history: "History",
  QRCode: "QR Code"
}

export const DEFAULT_SEARCH_PARAMS = { search: "", page_num: 1, page_size: 10 }

export const LIST_STATUS = [
  { value: "pending", label: "Pending" },
  { value: "open", label: "Open" },
  { value: "in_progress", label: "In progress" },
  { value: "completed", label: "Completed" }
]

export const objBgColorSeat = {
  white: "bg-white",
  yellow: "bg-yellow-200",
  red: "bg-red-200",
  blue: "bg-primary-50",
  grey: "bg-gray-200"
}

export const DEFAULT_TIME_DEBOUNCE = 400

export const DESTINATION_METHOD = {
  group: 1,
  user: 2
}

export const LIST_SENDING_STATUS = [
  {
    value: 1,
    label: "Sending",
    color: "red"
  },
  {
    value: 2,
    label: "Sent",
    color: "#12B981"
  },
  {
    value: 3,
    label: "Draft",
    color: "#646970"
  },
  {
    value: 4,
    label: "Scheduled",
    color: "#8E24AA"
  }
]
