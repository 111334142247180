import dashboard from "./dashboard.json"
import billingManagement from "./billing-management.json"
import userManagement from "./user-management.json"
import notification from "./notification-management.json"
import error from "./error.json"
import menu from "./menu.json"
import message from "./message.json"
import required from "./required.json"
import profile from "./user-profile.json"
import authentication from "./authentication.json"
import permissons from "./permissons.json"
import gradeManagement from "./grade-management.json"
import student from "./student-management.json"
import teacher from "./teacher-management.json"
import billing from "./billing-management.json"
import classManagement from "./class-management.json"
import report from "./report-management.json"
import salary from "./salary-management.json"
import school from "./school-management.json"
import system from "./system.json"
import chat from "./chat.json"
import attendance from "./attendance-management.json"
import teachingReport from "./teaching-report.json"

const translate = {
  ID: "ID",
  Email: "Email",
  Password: "Password",
  "Client Name": "Client Name",
  "Student List": "Student List",
  Packages: "Packages",
  "Created at": "Created at",
  "View details": "View details",
  "Forgot password": "Forgot password",
  "Remember login": "Remember login",
  "Log in your account": "Log in your account",
  Logout: "Logout",
  "Choose an option": "Choose an option",
  "Choose multiple option": "Choose multiple option",
  "Edit image": "Edit image",
  "File size exceeds the allowed limit 2 MB": "File size exceeds the allowed limit 2 MB",
  "This file doesn't support.": "This file doesn't support.",
  "Powered by": "Powered by",
  Verify: "Verify",
  Enable: "Enable",
  Disable: "Disable",
  "Address 1": "Address 1",
  "Address 2": "Address 2",
  "Notification Detail": "Notification Detail",
  "attachment files": "attachment files",
  s: "s",
  m: "m",
  h: "h",
  d: "d",
  ago: "ago",
  Done: "Done",
  "Picture here": "Picture here",
  "Supported formats: png, jpg": "Supported formats: png, jpg",
  "Custom domain setting": "Custom domain setting",
  "This page is comming soon": "This page is comming soon",
  "This page is not unknown or does not exsit": "This page is not unknown or does not exsit",
  "Sorry about that, but the page you looking for is not available":
    "Sorry about that, but the page you looking for is not available",
  "Service unavailable": "Service unavailable",
  "This server is temporary busy, try again later":
    "This server is temporary busy, try again later",
  email_support_refund: "to you. Please contact {{email}} to supported",
  Picture: "Picture",
  "List of user accounts managed by Super admin": "List of user accounts managed by Super admin",
  "after the street number": "after the street number",
  "to town": "to town",
  "e.g. Tokyo-to Shibuya-ku Sakuragaoka-cho": "e.g. Tokyo-to Shibuya-ku Sakuragaoka-cho",
  "e.g. 1-2-3 ABC Building, 4th Floor": "e.g. 1-2-3 ABC Building, 4th Floor",
  "Choose an area": "Choose an area",
  "Choose a school": "Choose a school",
  "Choose an official school": "Choose an official school",
  "Drag and drop CSV files here": "Drag and drop CSV files here",
  "Select file": "Select file",
  "Create new": "Create new",
  Search: "Search",
  Save: "Save",
  Cancel: "Search",
  ...dashboard,
  ...billingManagement,
  ...userManagement,
  ...notification,
  ...error,
  ...required,
  ...menu,
  ...message,
  ...profile,
  ...authentication,
  ...permissons,
  ...gradeManagement,
  ...student,
  ...teacher,
  ...billing,
  ...classManagement,
  ...report,
  ...salary,
  ...school,
  ...system,
  ...chat,
  ...attendance,
  ...teachingReport
}

export default translate
